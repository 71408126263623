import React, { useContext } from 'react';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FeatureThumbnailContext from '../../../../context/FeatureThumbnailContext';
import FormModalContext from '../../../../context/FormModalContext';

import { useStyles } from '../styles';
import { IconSvg } from '../../../IconSvg';

export const BodyFeature = ({ feature, selectedFeature, featureRef }) => {
  const classes = useStyles();

  const { handleSelectThumbnail } = useContext(FeatureThumbnailContext);

  const lg = useMediaQuery('(max-width: 1280px)');
  const xs = useMediaQuery('(max-width: 481px)');
  const md = useMediaQuery('(max-width: 960px)');

  const { handleModalClick } = useContext(FormModalContext);

  return (
    <div style={{ padding: '1rem 0' }}>
      {/* <FontAwesomeIcon
        icon={['fad', feature.specialFaIcon.name ?? 'fish']}
        style={{
          height: '60px',
          width: '60px',
          background: '#002D5C',
          margin: '0 0 15px 0',
          padding: '10px',
          borderRadius: '100%',
          '--fa-primary-color':
            feature.specialFaIcon.colors[0]?.color ?? '#AA23FF',
          '--fa-secondary-color':
            feature.specialFaIcon.colors[1].color ?? '#0cff00',
          '--fa-primary-opacity': 1,
          '--fa-secondary-opacity': 1,
        }}
      /> */}
      <Typography variant='body1' className={classes.h3HeaderTop}>
        {feature.bodySubHeader}
      </Typography>
      <Typography variant='h2' className={classes.h3Header}>
        {feature.bodyHeader}
      </Typography>
      <PortableText
        content={feature._rawBodyCopy}
        serializers={{
          normal: ({ children }) => (
            <Typography variant='body1' className={classes.bodyContent}>
              {children}
            </Typography>
          ),
          ul: ({ children }) => (
            <ul className={classes.scrollUl}>
              {React.Children.map(children, (child, i) =>
                React.isValidElement(child)
                  ? React.cloneElement(child, {
                      key: i,
                      children: (
                        <>
                          <span className={classes.icon}>
                            <IconSvg />
                          </span>
                          <Typography
                            variant='body1'
                            className={classes.bodyContent}
                          >
                            {child.props.children}
                          </Typography>
                        </>
                      ),
                    })
                  : null
              )}
            </ul>
          ),
          internalLink: ({ children, internalSlug }) => (
            <Link to={`/${internalSlug}`} className={classes.linkText}>
              {children}
            </Link>
          ),
        }}
      />
      {feature.ctaText && feature.ctaLink && (
        <Button
          variant='contained'
          size='large'
          className={classes.cta}
          href={feature.ctaLink}
          component='a'
        >
          {feature.ctaText}
        </Button>
      )}
      {feature.ctaText && !feature.ctaLink && (
        <Button
          variant='contained'
          size='large'
          className={classes.cta}
          onClick={handleModalClick}
        >
          {feature.ctaText}
        </Button>
      )}
    </div>
  );
};
