import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';

import { BodyFeature } from './BodyFeature';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    color: theme.workwaveBlue,
    marginLeft: '1.2rem',
    fontSize: '1.3rem',
  },
  card: {
    // border: '1px solid #ECECEE',
    boxShadow: '0px .5px 2px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    background: theme.white,
    transition: 'transform 0.25s, box-shadow 0.75s',
    maxWidth: '100%',
    marginBottom: '1.5rem !important',
    overflow: 'hidden',
    borderRadius: '20px !important',
  },
  expanded: {},
  root: {
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
      minHeight: 'unset',
    },
  },
}));

export const MobileFeatureCard = ({
  featureSection,
  feature,
  featureRef,
  index,
  // removed expanded and handleChange props
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Accordion
        defaultExpanded={index === 0} // First accordion is open by default
        className={classes.card}
        style={{
          border: '2px solid #E5EAF4',
          borderRadius: '20px !important',
          boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <AccordionSummary
          style={{ padding: 0 }}
          classes={{
            content: classes.content,
            expanded: classes.expanded,
            root: classes.root,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <FontAwesomeIcon
              icon={['fad', feature?.specialFaIcon?.name ?? 'fish']}
              style={{
                height: '60px',
                width: '60px',
                border: '1px solid #002D5C',
                background: '#002D5C',
                padding: '12px',
                '--fa-primary-color':
                  feature?.specialFaIcon?.colors[0]?.color ?? '#AA23FF',
                '--fa-secondary-color':
                  feature?.specialFaIcon?.colors[1].color ?? '#0cff00',
                '--fa-primary-opacity': 1,
                '--fa-secondary-opacity': 1,
              }}
            />
            <Typography variant="h5" className={classes.title}>
              {feature.cardTitle}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0 16px' }}>
          <BodyFeature feature={feature} featureRef={featureRef} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
