import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  topHeader: {
    color: theme.lightBlue,
    fontWeight: 700,
    marginTop: theme.spacing(2), // Ensures margin top for mobile
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  bottomHeader: {
    color: theme.workwaveBlue,
    fontWeight: 700,
    paddingTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1rem', // Slightly smaller padding on mobile
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '.75rem', // Slightly smaller padding on mobile
    },
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
  content: {
    margin: '0 auto', // Ensures centered content
  },
}));

export const Overview = ({ overviewBody, overviewImage, why }) => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width:600px)');
  return (
    <Grid
      
      direction="row"
      justifyContent="flex-start" // Adjusted for better alignment
     
      className={classes.content}
    >
      {!!overviewImage ? (
        <Grid
          item
         
          direction="row"
          alignItems="center"
          justifyContent="center"
          
          xs={12}
          md={5}
        >
          <GatsbyImage image={overviewImage?.asset?.gatsbyImageData} />
        </Grid>
      ) : null}
      <Grid
        item
        container
        xs={12}
        md={!overviewImage ? 12 : 7} // Adjusted for better distribution
       
        direction="column"
        justifyContent="flex-start"
        alignItems={med || why ? 'center' : 'flex-start'}
        style={{
          textAlign: why && !med ? 'center' : 'left', // Adjusted alignment for clarity
        }}
      >
        <Grid item>
          <PortableText
            content={overviewBody}
            serializers={{
              h2: ({ children }) => (
                <Typography variant="h2" className={classes.bottomHeader}>
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ paddingBottom: '1rem' }}
                >
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography variant="h4" className={classes.topHeader}>
                  {children}
                </Typography>
              ),
              normal: ({ children }) => (
                <Typography
                  variant="body1"
                  style={{
                    color: '#4B5B69',
                    lineHeight: 1.7,
                    // margin: !overviewImage && !med ? '1rem auto' : '0', // Adjusted margins
                  }}
                >
                  {children}
                </Typography>
              ),
              internalLink: ({ children, internalSlug }) => (
                <Link to={`/${internalSlug}`} className={classes.linkText}>
                  {children}
                </Link>
              ),
              strong: ({ children }) => (
                <span style={{ color: '#D10066' }}>{children}</span>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
