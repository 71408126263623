import React, { useState, useEffect } from 'react';

import { Container, useMediaQuery } from '@material-ui/core';

import { MobileFeatures } from './Mobile/MobileFeatures';
import { DesktopFeatures } from './DesktopFeatures';

export const OverflowScroll = ({ features, text }) => {
  const md = useMediaQuery('(max-width: 960px)');
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(features[0]);
  }, []);
  return (
    <Container style={{ position: 'relative', zIndex: 3 }}>
      {md ? (
        <MobileFeatures
          features={features}
          selected={selected}
          setSelected={setSelected}
          text={text}
        />
      ) : !!selected ? (
        <DesktopFeatures
          features={features}
          setSelected={setSelected}
          selected={selected}
          text={text}
        />
      ) : null}
    </Container>
  );
};
