import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},

  centerFeatures: {
    background: 'transparent',
    padding: '14rem 0 16rem',
    [theme.breakpoints.down('sm')]: {
      padding: '8rem 0 12rem 0',
    },
  },

  subHeader: {
    fontFamily: 'Roboto',
    fontSize: '1.3125rem',
    fontWeight: 500,
    textAlign: 'left',
    color: '#4B5B69',
    paddingBottom: '10px',
  },

  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  card: {
    opacity: 1,
    background: 'white',
    marginBottom: '2rem',
    padding: '4rem',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
  },

  cardHeader: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  // cardImg: {
  //   maxWidth: '280px',
  //   margin: '0 auto',
  //   display: 'block',
  // },
  pinkHighlight: {
    color: '#D10066',
  },
  h3HeaderTop: {
    textAlign: 'left',
    color: '#D10066',
    paddingBottom: '0px',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  h3Header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    fontFamily: '"Poppins", sans-serif',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  bodyContent: {
    textAlign: 'left',
  },
  cta: {
    marginTop: '20px',
    backgroundColor: '#0380B6',
    color: '#ffffff',
    fontWeight: '600',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#002d5c',
    },
  },
  // card: {
  //   marginBottom: '7rem',
  //   [theme.breakpoints.down('sm')]: {
  //     marginBottom: '5rem',
  //   },
  //   '&:last-child': {
  //     marginBottom: 0, // Remove margin for the last card
  //   },
  // },
  boxImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
  scrollUl: {
    listStyle: 'none',
    padding: 0,
    margin: '1.5rem 0 0 0',
    '& li': {
      marginBottom: '.75rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  icon: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'sticky',
    top: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
  topHeader: {
    color: theme.lightBlue,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  bottomHeader: {
    color: theme.workwaveBlue,
    fontWeight: 700,
    paddingTop: '1rem',
    // paddingBottom: '1rem',
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
}));
