import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import SectionPaddingContext from '../../context/SectionPaddingContext';

const useStyles = makeStyles((theme) => ({
  sectionWhite: {
    position: 'relative',
    background: '#F4F8FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export const SectionLightBlue = ({
  top = false,
  bottom = false,
  extraTop = false,
  extraBottom = false,
  additionalTop = 0,
  additionalBottom = 0,
  marginTop = '0px',
  marginBottom = '0px',
  zIndex,
  children,
}) => {
  const classes = useStyles();

  const { paddingTop, paddingTop2, paddingBottom, paddingBottom2 } = useContext(
    SectionPaddingContext
  );

  const finalPaddingTop = extraTop ? paddingTop2 : top ? paddingTop : 0;
  const finalPaddingBottom = extraBottom
    ? paddingBottom2
    : bottom
    ? paddingBottom
    : 0;
  return (
    <div
      className={`${classes.sectionWhite}`}
      style={{
        paddingTop: `${finalPaddingTop + additionalTop}px`,
        paddingBottom: `${finalPaddingBottom + additionalBottom}px`,
        marginTop,
        marginBottom,
        zIndex: zIndex ? zIndex : null,
      }}
    >
      {children}
    </div>
  );
};
