import React, { useContext } from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Container,
  Typography,
  useMediaQuery,
  Button,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FormModalContext from '../../../context/FormModalContext';
import { IconSvg } from '../../IconSvg';

const useStyles = makeStyles((theme) => ({
  root: {},

  centerFeatures: {
    background: 'transparent',

    [theme.breakpoints.down('sm')]: {},
  },

  subHeader: {
    fontFamily: 'Roboto',
    fontSize: '1.3125rem',
    fontWeight: 500,
    textAlign: 'left',
    color: '#4B5B69',
    paddingBottom: '10px',
  },

  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  card: {
    opacity: 1,
    background: 'white',
    marginBottom: '4rem',
    padding: '2rem',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
  },

  cardHeader: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  // cardImg: {
  //   maxWidth: '280px',
  //   margin: '0 auto',
  //   display: 'block',
  // },
  pinkHighlight: {
    color: '#D10066',
  },
  h3HeaderTop: {
    textAlign: 'left',
    color: '#D10066',
    paddingBottom: '0px',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '-0.5px',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  h3Header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    fontFamily: '"Poppins", sans-serif',
    zIndex: 10,
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  bodyContent: {
    textAlign: 'left',
  },
  cta: {
    marginTop: '20px',
    backgroundColor: '#0380B6',
    color: '#ffffff',
    fontWeight: '600',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#002d5c',
    },
  },
  // card: {
  //   marginBottom: '7rem',
  //   [theme.breakpoints.down('sm')]: {
  //     marginBottom: '5rem',
  //   },
  //   '&:last-child': {
  //     marginBottom: 0, // Remove margin for the last card
  //   },
  // },
  boxImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
  scrollUl: {
    listStyle: 'none',
    padding: 0,
    margin: '1.5rem 0 0 0',
    '& li': {
      marginBottom: '.75rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  icon: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'sticky',
    top: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
  topHeader: {
    color: theme.lightBlue,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  bottomHeader: {
    color: theme.workwaveBlue,
    fontWeight: 700,
    paddingTop: '1rem',
    // paddingBottom: '1rem',
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
}));

export const DesktopFeatures = ({ features, text, selected, setSelected }) => {
  const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);

  const md = useMediaQuery('(max-width: 960px)');
  return (
    <div className={classes.root}>
      <div className={classes.centerFeatures}>
        <Container>
          <Grid
            container
            alignItems='flex-start'
            justifyContent='space-between'
            spacing={4}
            style={{ position: 'relative' }}
          >
            <Grid
              container
              direction='column'
              item
              xs={6}
              style={{ position: 'sticky', top: '33%', height: 'auto' }}
            >
              <PortableText
                content={text}
                serializers={{
                  h2: ({ children }) => (
                    <Typography variant='h2' className={classes.bottomHeader}>
                      {children}
                    </Typography>
                  ),
                  normal: ({ children }) => (
                    <Typography
                      variant='body1'
                      style={{
                        color: '#4B5B69',
                        lineHeight: 1.4,
                        margin: '1rem 0',
                      }}
                    >
                      {children}
                    </Typography>
                  ),
                  strong: ({ children }) => (
                    <span style={{ color: '#D10066' }}>{children}</span>
                  ),
                  em: ({ children }) => (
                    <Button
                      size='large'
                      color='primary'
                      variant='contained'
                      onClick={handleModalClick}
                      style={{ padding: '14px 24px' }}
                    >
                      {children}
                    </Button>
                  ),
                }}
              />
            </Grid>

            <Grid
              container
              justifyContent='flex-start'
              alignItems='center'
              item
              xs={6}
              spacing={2}
            >
              {features.map((feature, index) => (
                <div
                  className={classes.card}
                  style={{ width: '100%' }}
                  key={index}
                >
                  <FontAwesomeIcon
                    icon={['fad', feature.specialFaIcon?.name ?? 'fish']}
                    style={{
                      height: '60px',
                      width: '60px',
                      background: '#002D5C',
                      margin: '0 0 15px 0',
                      padding: '10px',
                      borderRadius: '100%',
                      '--fa-primary-color':
                        feature.specialFaIcon?.colors[0]?.color ?? '#AA23FF',
                      '--fa-secondary-color':
                        feature.specialFaIcon?.colors[1].color ?? '#0cff00',
                      '--fa-primary-opacity': 1,
                      '--fa-secondary-opacity': 1,
                    }}
                  />
                  <Typography variant='body1' className={classes.h3HeaderTop}>
                    {feature.bodySubHeader}
                  </Typography>
                  <Typography variant='h2' className={classes.h3Header}>
                    {feature.bodyHeader}
                  </Typography>
                  <PortableText
                    content={feature._rawBodyCopy}
                    serializers={{
                      normal: ({ children }) => (
                        <Typography
                          variant='body1'
                          className={classes.bodyContent}
                        >
                          {children}
                        </Typography>
                      ),
                      ul: ({ children }) => (
                        <ul className={classes.scrollUl}>
                          {React.Children.map(children, (child, i) =>
                            React.isValidElement(child)
                              ? React.cloneElement(child, {
                                  key: i,
                                  children: (
                                    <>
                                      <span className={classes.icon}>
                                        <IconSvg />
                                      </span>
                                      <Typography
                                        variant='body1'
                                        className={classes.bodyContent}
                                      >
                                        {child.props.children}
                                      </Typography>
                                    </>
                                  ),
                                })
                              : null
                          )}
                        </ul>
                      ),
                      internalLink: ({ children, internalSlug }) => (
                        <Link
                          to={`/${internalSlug}`}
                          className={classes.linkText}
                        >
                          {children}
                        </Link>
                      ),
                    }}
                  />
                  {feature.ctaText && feature.ctaLink && (
                    <Button
                      variant='contained'
                      size='large'
                      className={classes.cta}
                      href={feature.ctaLink}
                      component='a'
                    >
                      {feature.ctaText}
                    </Button>
                  )}
                  {feature.ctaText && !feature.ctaLink && (
                    <Button
                      variant='contained'
                      size='large'
                      className={classes.cta}
                      onClick={handleModalClick}
                    >
                      {feature.ctaText}
                    </Button>
                  )}
                  {md ? (
                    <GatsbyImage
                      image={feature.image.featureImage.asset.gatsbyImageData}
                      imgStyle={{ objectFit: 'contain' }}
                      style={{ marginTop: '2rem' }}
                      loading='eager'
                    />
                  ) : null}
                </div>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
