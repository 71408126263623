import React, { useState, useRef, useContext } from 'react';
import PortableText from 'react-portable-text';

import { Grid, Typography, Container, makeStyles } from '@material-ui/core';

import { MobileFeatureCard } from './MobileFeatureCard';

import FormModalContext from '../../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  header: {
    lineHeight: 1.4,
    fontWeight: 700,
    color: '#002D5C',
    paddingBottom: '1rem',
  },
}));

export const MobileFeatures = ({ features, text, featureRef }) => {
  const classes = useStyles();
  const mobileCardRef = useRef(null);
  const [expanded, setExpanded] = useState(0);

  const { handleModalClick } = useContext(FormModalContext);

  const handleChange = async (index) => {
    await setExpanded(index);
    mobileCardRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (
    <div
     style={{position: 'relative', zIndex: 3}}
    >
      
        <PortableText
          content={text}
          serializers={{
            h2: ({ children }) => (
              <Typography variant='h2' color='primary' className={classes.bottomHeader}>
                {children}
              </Typography>
            ),
            normal: ({ children }) => (
              <Typography
                variant='body1'
                style={{
                  color: '#4B5B69',
                  lineHeight: 1.7,
                  margin: '1rem 0',
                }}
              >
                {children}
              </Typography>
            ),
            strong: ({ children }) => (
              <span style={{ color: '#D10066' }}>{children}</span>
            ),
            em: ({ children }) => (
              <span
                style={{
                  color: '#002D5C',
                  fontWeight: 700,
                  fontSize: '24px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={handleModalClick}
              >
                {children}
              </span>
            ),
          }}
        />
      
      <div ref={mobileCardRef} />
      {features.map((feature, index) => (
        <MobileFeatureCard
          feature={feature}
          index={index}
          key={index}
          featureRef={featureRef}
          expanded={expanded}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};
